/**
 * The file contains the webapp's Sentry Integration.
 *
 * Follow the getting started guide to complete this integration:
 * https://docs.sentry.io/error-reporting/quickstart/?platform=browsernpm#configure-the-sdk
 *
 * For more information on capturing errors throughout your application, take
 * a look at the Sentry documentation:
 * https://docs.sentry.io/platforms/javascript/?platform=browsernpm#capturing-errors
 */
import * as Sentry from '@sentry/react';

if (process.env.SENTRY_DSN) {
  /**
   * Sentry configuration:
   * https://docs.sentry.io/error-reporting/configuration/?platform=browsernpm
   */
  const sentryOptions = {
    blacklistUrls: [],
    dsn: process.env.SENTRY_DSN,
    environment: process.env.SENTRY_ENVIRONMENT,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    replaysOnErrorSampleRate: 1.0,
    ignoreErrors: [],
    release: process.env.SENTRY_RELEASE,
  };

  Sentry.init(sentryOptions);

  /**
   * To test that your Sentry integration is set up correctly, uncomment the
   * following code snippet and then build and run the application. You should see
   * this test error captured in the Sentry dashboard for your project.
   */
  // Sentry.captureException(new Error('This is my fake error message'));
}
